import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BaseTextFormFieldComponent } from '../base/base-form-field.component';
import { AbstractControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { __ } from '@app/shared/functions/object.functions';

@Component({
  selector: 'jad-form-fields-slider',
  imports: [
    MatSliderModule,
    ReactiveFormsModule,
  ],
  templateUrl: './form-fields-slider.component.html',
  styleUrl: './form-fields-slider.component.scss',
  standalone: true
})
export class JadFormFieldsSliderComponent extends BaseTextFormFieldComponent implements OnInit {

    // -----------------------------------------------------------------------------------------------------
    // @ INPUT VARIABLES
    // -----------------------------------------------------------------------------------------------------
  
      @Input() customLabelFormatter: (value: number) => string;

      @Input() shouldDisplayValue: boolean = true;
  
      @Input() step: number = 1;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    protected cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder
  ) {
    super(cdr);
  }

    // -----------------------------------------------------------------------------------------------------
    // @ LIFE CYCLE HOOKS
    // -----------------------------------------------------------------------------------------------------
  
    ngOnInit(): void {
      this.setForm(); 
    }
  
    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------
  
    setForm(): void {
      if (__.IsNullOrUndefined(this.control)) {
        this.control = this.fb.group({
          sliderValue: this.max,
        });
      }
    }
  
    getAsFormGroup(control: AbstractControl): UntypedFormGroup {
      return control as UntypedFormGroup;
    }

}
