<div class="py-10 sm:py-10 md:py-5 lg:py-5 xl:py-5 2xl:py-5">
    <div class="px-5 mb-5">

        <jad-form-fields-slider [control]="form.get('bpm')" [displayName]="'Browse.BPM' | translate" [min]="bpmMin"
            [max]="bpmMax"></jad-form-fields-slider>

        <div>
            <div class="gap-2 flex flex-wrap pb-2">
                <sound-tags-label *ngFor="let range of BPMRanges.slice(0, 3)"
                    [isActive]="checkActiveBpmRange(range.value)"
                    [displayText]="getTranslationEquivalent(range.displayName) | translate"
                    (onClick)="setActiveRange(range.value)"></sound-tags-label>
                <sound-tags-label *ngFor="let range of BPMRanges.slice(3)" [isActive]="checkActiveBpmRange(range.value)"
                    [displayText]="getTranslationEquivalent(range.displayName) | translate"
                    (onClick)="setActiveRange(range.value)"></sound-tags-label>
            </div>

        </div>

    </div>

    <mat-divider class="sub-sidebar__divider" style="margin-top: 20px; margin-bottom: 20px; border-top: 1px dashed #3e3a40;"></mat-divider>

    <div class="px-5 mb-5">

        <jad-form-fields-slider [control]="form.get('duration')" [displayName]="'Browse.Duration' | translate"
            [min]="durationMin" [max]="durationMax" [customLabelFormatter]="durationFormatter"></jad-form-fields-slider>

    </div>

    <mat-divider class="sub-sidebar__divider" style="margin-top: 20px; margin-bottom: 20px; border-top: 1px dashed #3e3a40;"></mat-divider>

    <div class="px-5">
        <span>{{ 'Browse.Vocals' | translate }}</span>

        <div class="gap-2 flex flex-wrap mt-4">
            <sound-tags-label *ngFor="let vocal of Vocals" [displayText]="vocal.displayName | translate"
                [isActive]="checkActiveVocals(vocal.value)" (onClick)="setVocals(vocal.value)"></sound-tags-label>
        </div>
    </div>
</div>