<!-- CONTAINER -->
<div class="music-player-volume__container flex flex-row gap-1 lg:gap-5 justify-center items-center">

  <!-- MUTE TOGGLE -->
  <div class="music-player-volume__toggle flex-initial flex flex-row justify-center">
    <mat-icon class="large cursor-pointer" [svgIcon]="muteToggleIcon" (click)="toggleMute()">
    </mat-icon>
  </div>

    <!-- VOLUME SLIDER -->
    <div class="music-player-volume__slider w-10 sm:w-24">
      <jad-form-fields-slider [control]="volumeForm" [step]="1" [min]="0" [max]="100" [shouldDisplayValue]="false"></jad-form-fields-slider>
    </div>

  </div>