<!-- CONTAINER -->
<div class="sounddesign__container flex flex-col items-start">

  <!-- HEADER 
  <h1 class="sounddesign__header mb-8">
    <strong>
      {{ 'Sounddesign.Sounddesign' | translate }}
    </strong>
  </h1>-->

  <!-- CONTENT -->
  <div class="sounddesign__content text-left max-w-full ">

    <img src="/assets/bg_dolby_atmos.jpg" alt="Sounddesign Header Image" class="w-full mb-4">


    <div class="html-content" [innerHTML]="'Sounddesign.Content' | translate | safe: 'html'">
    </div>
    <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1031961249?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Dolby Atmos Demo Comparison - Headphone only!"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
    <div class="sounddesign__action text-center mb-8 flex flex-row">
      <sound-button-default [displayText]="'Sounddesign.CTA' | translate" [colorClass]="'purple'"
        [icon]="'arrow_right'" [order]="'icon-last'" [link]="'/contact-us'"></sound-button-default>
    </div>
      </div>

  <!-- BUTTON -->
  <div class="dolby-atmos__button text-center mb-8 flex flex-row">
    <sound-button-default (onClick)="scrollUp()" [icon]="'arrow_simple_right'" [order]="'icon-last'" [displayText]="'Dolby Atmos.Scroll Up' | translate">
    </sound-button-default>
  </div>
  

</div>