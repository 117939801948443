import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@app/shared/shared.module';
import { MusicPlayerVolumeComponent } from './music-player-volume.component';
import { JadFormFieldsSliderComponent } from '../../form-fields/slider/form-fields-slider.component';

@NgModule({
  imports: [
    SharedModule,
    MatIconModule,
    JadFormFieldsSliderComponent
  ],
  declarations: [MusicPlayerVolumeComponent],
  exports: [MusicPlayerVolumeComponent]
})
export class MusicPlayerVolumeModule { }
