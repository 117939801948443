import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { BaseTextFormFieldComponent } from '../base/base-form-field.component';
import { __ } from '@app/shared/functions/object.functions';

@Component({
  selector: 'jad-form-fields-slider',
  imports: [
    MatSliderModule,
    ReactiveFormsModule,
  ],
  templateUrl: './form-fields-range-slider.component.html',
  styleUrl: './form-fields-range-slider.component.scss',
  standalone: true
})
export class FormFieldsRangeSliderComponent extends BaseTextFormFieldComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

    @Input() customLabelFormatter: (value: number) => string;

    @Input() shouldDisplayMax: boolean = true;

    @Input() shouldDisplayMin: boolean = true;

    @Input() step: number = 1;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    protected cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder
  ) {
    super(cdr);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.setForm(); 
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  setForm(): void {
    if (__.IsNullOrUndefined(this.control)) {
      this.control = this.fb.group({
        min: this.min,
        max: this.max,
      });
    }
  }

  getAsFormGroup(control: AbstractControl): UntypedFormGroup {
    return control as UntypedFormGroup;
  }

}
