import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@app/shared';
import { ButtonsDefaultModule } from '@app/shared/components/buttons/default/buttons-default.module';
import { JadFormFieldsMultiRangeModule } from '@app/shared/components/form-fields/multi-range/form-fields-multi-range.module';
import { TagsLabelModule } from '@app/shared/components/tags/label/tags-label.module';
import { ArtistService } from '@app/shared/services/artist.service';
import { GenreService } from '@app/shared/services/genre.service';
import { InstrumentService } from '@app/shared/services/instrument.service';
import { MoodService } from '@app/shared/services/mood.service';
import { ThemeService } from '@app/shared/services/theme.service';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationLinkModule } from '../../navigation/link/navigation-link.module';
import { SideBarAdvancedSubsubnavigationComponent } from './advanced/side-bar-advanced-subsubnavigation.component';
import { SideBarListSubsubnavigationComponent } from './list/side-bar-list-subsubnavigation.component';
import { SideBarSubsubnavigationComponent } from './side-bar-subsubnavigation.component';
import { NormalizePipe } from '@app/shared/pipes/normalize.pipe';
import { FormFieldsRangeSliderComponent } from '@app/shared/components/form-fields/range-slider/form-fields-range-slider.component';

@NgModule({
  imports: [
    SharedModule,
    NavigationLinkModule,
    JadFormFieldsMultiRangeModule,
    ReactiveFormsModule,
    TranslateModule,
    TagsLabelModule,
    MatDividerModule,
    ButtonsDefaultModule,
    MatIconModule,
    FormFieldsRangeSliderComponent
  ],
  providers: [
    GenreService, 
    MoodService, 
    ThemeService, 
    InstrumentService, 
    ArtistService,
    NormalizePipe
  ],
  declarations: [
    SideBarSubsubnavigationComponent,
    SideBarAdvancedSubsubnavigationComponent,
    SideBarListSubsubnavigationComponent
  ],
  exports: [SideBarSubsubnavigationComponent]
})
export class SideBarSubsubnavigationModule { }
