<div class="h-auto">
    <!-- LABEL -->
    @if(!IsNullOrUndefinedOrEmpty(label) || !IsNullOrUndefinedOrEmpty(displayName)) {
    <div class="">
        {{ !IsNullOrUndefinedOrEmpty(label) ? label : displayName }} {{ required === true ? '*' : '' }}
    </div>
    }

    @if(shouldDisplayMin === true || shouldDisplayMax === true) {
    <div class="flex justify-between text-sm font-medium text-secondary-gray-60 mt-4">
        <span>
            @if(shouldDisplayMin === true) {
            {{ IsNullOrUndefinedOrEmpty(customLabelFormatter) ? this.control.get('min')?.value :
            customLabelFormatter(this.control.get('min')?.value) }}
            }
        </span>
        <span>
            @if(shouldDisplayMax === true) {
            {{ IsNullOrUndefinedOrEmpty(customLabelFormatter) ? this.control.get('max')?.value :
            customLabelFormatter(this.control.get('max')?.value) }}{{ this.control.get('max')?.value === max ? '+' :
            ''}}
            }
        </span>
    </div>
    }

    <form [formGroup]="getAsFormGroup(control)">
        <mat-slider [min]="min" [max]="max" [step]="step" [disableRipple]="true">
            <input formControlName="min" matSliderStartThumb>
            <input formControlName="max" matSliderEndThumb>
        </mat-slider>
    </form>
</div>