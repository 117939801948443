<div class="h-auto">
    <!-- LABEL -->
    @if(!IsNullOrUndefinedOrEmpty(label) || !IsNullOrUndefinedOrEmpty(displayName)) {
    <div class="">
        {{ !IsNullOrUndefinedOrEmpty(label) ? label : displayName }} {{ required === true ? '*' : '' }}
    </div>
    }
    @if(shouldDisplayValue === true) {
    <div class="flex justify-center text-sm font-medium text-secondary-gray-60 mt-6">
        <span>
            {{ IsNullOrUndefinedOrEmpty(customLabelFormatter) ? this.control.get('max')?.value :
            customLabelFormatter(this.control.get('sliderValue')?.value) }}{{ this.control.get('sliderValue')?.value === max ?
            '+' :
            ''}}
        </span>
    </div>
    }

    <form [formGroup]="getAsFormGroup(control)">
        <mat-slider [min]="min" [max]="max" [step]="step" [disableRipple]="true">
            <input formControlName="sliderValue" matSliderThumb>
        </mat-slider>
    </form>
</div>