import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { __ } from '@app/shared/functions/object.functions';
import { SubSubNavigationView } from '@app/shared/models/enums/SubSubNavigationView.enum';
import { BPMRangesHelper } from '@app/shared/models/helpers/BPMRanges.helper';
import { BreakpointHelper } from '@app/shared/models/helpers/breakpoints.helper';
import { VocalTypesHelper } from '@app/shared/models/helpers/Vocals.helper';
import { MinuteSecondsPipe } from '@app/shared/pipes/minute-seconds.pipe';
import { BaseMusicFilterSort, MusicFilter, MusicFilterService } from '@app/shared/services/local/music-filter.service';
import { SidebarService } from '@app/shared/services/local/side-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs';

@Component({
    selector: 'sound-side-bar-advanced-subsubnavigation',
    templateUrl: './side-bar-advanced-subsubnavigation.component.html',
    styleUrls: ['./side-bar-advanced-subsubnavigation.component.scss'],
    standalone: false
})
export class SideBarAdvancedSubsubnavigationComponent extends BaseComponent implements OnInit {

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    form: UntypedFormGroup;

    bpmMax: number = 180;

    bpmMin: number = 0;

    bpmFilter: MusicFilter;

    durationMax: number = 1200;

    durationMin: number = 0;

    durationFilter: MusicFilter;

    convertedDurationMax: string;

    BPMRanges = BPMRangesHelper.BPMRanges;

    Vocals = VocalTypesHelper.VocalTypes.filter(q => q.value !== 'Instrumental');

    sliderWidth: number = 248;

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(
        private fb: UntypedFormBuilder,
        private filtersService: MusicFilterService,
        private translateService: TranslateService,
        private sideBarService: SidebarService,
        private breakpointObserver: BreakpointObserver,
        private minuteSeconds: MinuteSecondsPipe
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ LIFE CYCLE HOOKS
    // -----------------------------------------------------------------------------------------------------

    ngOnInit() {
        console.log(Breakpoints.Medium);
        super.addSubscription(this.breakpointObserver
            .observe(BreakpointHelper.AllBreakPoints).subscribe({
                next: (bs: BreakpointState) => {
                    if (bs.matches && bs.breakpoints[BreakpointHelper.Small] === true) {
                        this.sliderWidth = 210;
                    } else {
                        this.sliderWidth = 248;
                    }

                    this.setFormFieldsAndFilters();
                    this.convertedDurationMax = this.durationFormatter(this.durationMax);
                    this.initSubscriptions();
                }
            })
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    durationFormatter: (value: number) => string = (value: number) => {
        return this.minuteSeconds.transform(value);
    }

    setNestedActiveSubSubNavigation(view: SubSubNavigationView): void {
        this.sideBarService.toggleSubBrowseSideBar(view);
    }

    setFormFieldsAndFilters(): void {
        this.form = this.fb.group({
            bpm: this.fb.group({
                min: 0,
                max: 180
            }),
            duration: this.fb.group({
                min: 0,
                max: 1200
            }),
            vocals: [[]]
        });

        this.bpmFilter = Object.assign(new MusicFilter(), {
            key: 'bpm',
            title: 'Browse.BPM filter',
            type: 'Include',
            value: [0, 180]
        });

        this.durationFilter = Object.assign(new MusicFilter(), {
            key: 'duration',
            title: 'Browse.Duration filter',
            type: 'Include',
            value: [0, 1200]
        });
    }

    setActiveRange(range: { min: number, max: number }): void {
        this.form.get(['bpm', 'min']).setValue(range.min);
        this.form.get(['bpm', 'max']).setValue(range.max);
    }

    setVocals(value: string): void {
        const valueIndex = this.form.get('vocals').value.findIndex((q: any) => q === value);

        if (valueIndex === -1) {
            this.form.get('vocals').value.push(value);

            const newVocalFilter = Object.assign(new MusicFilter(), {
                key: 'vocals',
                title: VocalTypesHelper.GetVocalsByValueOrNull(value).displayName,
                value,
                type: 'Include'
            });

            this.filtersService.pushOrUpdateFilterOrSort(newVocalFilter);
        } else {
            this.form.get('vocals').value.splice(valueIndex, 1);

            this.filtersService.removeFilterByDisplayName(VocalTypesHelper.GetVocalsByValueOrNull(value).displayName);
        }
    }

    getTranslationEquivalent(value: string): string {
        return `Browse.${value}`;
    }

    checkActiveVocals(value: string): boolean {
        return !this.IsNullOrUndefinedOrEmpty(this.form.get('vocals').value.find((q: any) => q === value));
    }

    checkActiveBpmRange(values: { min: number, max: number }) {
        return this.form.get(['bpm', 'min']).value === values.min && this.form.get(['bpm', 'max']).value === values.max;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PRIVATE METHODS
    // -----------------------------------------------------------------------------------------------------

    private initSubscriptions() {

        // Track and update filters coming from the music filter service     

        this.updateAdvancedMusicFilters(this.filtersService.filters);

        super.addSubscription(
            this.filtersService.filters$.subscribe({
                next: (filters: BaseMusicFilterSort[]) => {
                    this.updateAdvancedMusicFilters(filters);
                }
            })
        );

        // Value changes subscriptions

        super.addSubscription(
            this.form.get('bpm').valueChanges.pipe(
                debounceTime(200)
            ).subscribe({
                next: (formArray: any) => {
                    if (formArray.min !== this.bpmFilter.value[0] || formArray.max !== this.bpmFilter.value[1]) {
                        this.bpmFilter.value = [formArray.min, formArray.max];
                        this.bpmFilter.title = this.translateService.instant('Browse.BPM filter', { min: formArray.min, max: formArray.max });

                        this.filtersService.pushOrUpdateFilterOrSort(this.bpmFilter);
                    }
                }
            })
        );

        super.addSubscription(
            this.form.get('duration').valueChanges.pipe(
                debounceTime(200),
            ).subscribe({
                next: (formArray: any) => {
                    if (formArray.min !== this.durationFilter.value[0] || formArray.max !== this.durationFilter.value[1]) {
                        this.durationFilter.value = [formArray.min, formArray.max];
                        this.durationFilter.title = this.translateService.instant('Browse.Duration filter',
                            { min: this.durationFormatter(formArray.min), max: this.durationFormatter(formArray.max) });

                        this.filtersService.pushOrUpdateFilterOrSort(this.durationFilter);
                    }
                }
            })
        );
    }

    private updateAdvancedMusicFilters(filters: BaseMusicFilterSort[]): void {
        const relevantVocalMusicFilters = filters.filter(q => this.Vocals.some(p => p.displayName === q.title));

        if (this.IsNullOrUndefinedOrEmpty(relevantVocalMusicFilters)) {
            this.form.get('vocals').setValue([], { emitEvent: false });
        } else {
            const presentVocals = relevantVocalMusicFilters.map(q => (q as MusicFilter).value);

            this.form.get('vocals').setValue([...presentVocals], { emitEvent: false });
        }

        // bpm could also be a sorting filter
        const bpmMusicFilter = filters.find(q => q.key === 'bpm' && q instanceof MusicFilter);

        if (this.IsNullOrUndefinedOrEmpty(bpmMusicFilter)) {
            this.bpmFilter.value = [0, 180];

            this.form.get(['bpm', 'max']).setValue(180);
            this.form.get(['bpm', 'min']).setValue(0);
        } else {
            const convertedBpmFilter = bpmMusicFilter as MusicFilter;

            if (this.form.get(['bpm', 'max']).value !== convertedBpmFilter.value[1] || this.form.get(['bpm', 'min']).value !== convertedBpmFilter.value[0]) {
                this.bpmFilter.value = [...convertedBpmFilter.value];

                this.form.get(['bpm', 'max']).setValue(convertedBpmFilter.value[1]);
                this.form.get(['bpm', 'min']).setValue(convertedBpmFilter.value[0]);
            }
        }

        const durationMusicFilter = filters.find(q => q.key === 'duration' && q instanceof MusicFilter);

        if (this.IsNullOrUndefinedOrEmpty(durationMusicFilter)) {
            this.durationFilter.value = [0, 1200];

            this.form.get(['duration', 'max']).setValue(1200);
            this.form.get(['duration', 'min']).setValue(0);
        } else {
            const convertedDurationFilter = durationMusicFilter as MusicFilter;

            if (this.form.get(['duration', 'max']).value !== convertedDurationFilter.value[1] ||
                this.form.get(['duration', 'min']).value !== convertedDurationFilter.value[0]) {
                this.durationFilter.value = [...convertedDurationFilter.value];

                this.form.get(['duration', 'max']).setValue(convertedDurationFilter.value[1]);
                this.form.get(['duration', 'min']).setValue(convertedDurationFilter.value[0]);
            }
        }
    }
}